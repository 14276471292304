import React, { memo, useEffect, useState } from 'react';
import TruncatedTextSystem from '../../../../../DesignSystem/TruncatedTextSystem';

const Name = memo(({ name, width, onBlur, isValidRole }) => {
  const [value, setValue] = useState(name);

  useEffect(() => {
    setValue(name || '');
  }, [name]);

  const handleChange = ({ target }) => {
    const { value } = target;
    setValue(value);
  };

  const handleBlur = () => {
    onBlur(value);
  };

  if (!isValidRole)
    return (
      <TruncatedTextSystem
        name={name}
        maxWidth={width > 1600 ? 130 : 80}
        placement={'topLeft'}>
        <span className="name-column">{name}</span>
      </TruncatedTextSystem>
    );

  return (
    <input
      type="text"
      name="name"
      className="name-column-input"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
    />
  );
});

export default Name;
