import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import './index.css';
import CardsGeneral from './CardsGeneral';
import ModalPassword from '../ModalPassword';
import { useSelector, useDispatch } from 'react-redux';
import { userService } from '../../../../services/user.service';
import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { withTranslation } from 'react-i18next';
import { userActions } from '../../../../redux/actions/userActions';
import EditableAvatar from '../../../Avatar/EditableAvatar';

function SettinsUser(props) {
  const dispatch = useDispatch();
  const { InfoUser, setInfoUser, t } = props;
  const [Visible, setVisible] = useState(false);
  const user = useSelector((state) => state.userState.user);

  const permissions = useConfigurationPermissions();
  const onlyRead = permissions.user !== 'AC';

  // Execute modal
  const showModal = () => {
    setVisible(true);
  };

  // get new value from user
  const changeInput = (name, value) => {
    setInfoUser({
      ...InfoUser,
      [name]: value
    });
  };

  const updateUser = async () => {
    const newUser = {
      activation_token: InfoUser.activation_token,
      companyId: InfoUser.companyId,
      country: InfoUser.country,
      createdAt: InfoUser.createdAt,
      dni: InfoUser.dni,
      email: InfoUser.email,
      id: InfoUser.id,
      image: InfoUser.image,
      is_active: InfoUser.is_active,
      name: InfoUser.name,
      lastname: InfoUser.lastname,
      position: InfoUser.position,
      role: InfoUser.role,
      updatedAt: InfoUser.updatedAt
    };
    localStorage.setItem('user', JSON.stringify(newUser));
    await userService.update(newUser);
    dispatch(userActions.setUser(newUser));
  };

  return (
    <Fragment>
      <div className="grid-settings-user-general">
        <div>
          <EditableAvatar
            user={user}
            size={100}
            className="FrmAvatar avatar-user-settings"
          />
        </div>
        <div>
          <h1 className="projects-title">{t('settings.user_form.info')}</h1>
          <p className="description-form">{t('settings.user_form.name')}</p>
          <Form.Item name="group">
            <Input
              name="name"
              placeholder="Name"
              title={InfoUser ? InfoUser.name : ''}
              style={{ border: '1px solid #121212' }}
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.name : ''}
            />
          </Form.Item>
          <p className="description-form" style={{ marginTop: '28px' }}>
            {t('settings.user_form.email')}
          </p>
          <Form.Item name="group">
            <Input
              disabled
              name="email"
              placeholder="E-mail"
              style={{ border: '1px solid #121212' }}
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.email : ''}
            />
          </Form.Item>
        </div>

        <div style={{ marginTop: '32px' }}>
          <p className="description-form">{t('settings.user_form.lastname')}</p>
          <Form.Item name="group">
            <Input
              name="lastname"
              style={{ border: '1px solid #121212' }}
              placeholder="Last Name"
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.lastname : ''}
            />
          </Form.Item>
          <p className="description-form" style={{ marginTop: '28px' }}>
            {t('settings.user_form.position')}
          </p>
          <Form.Item name="group">
            <Input
              disabled={onlyRead}
              name="position"
              style={{ border: '1px solid #121212' }}
              placeholder="Cargo"
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.position : ''}
            />
          </Form.Item>
        </div>

        <div style={{ marginTop: '32px' }}>
          <p className="description-form">{t('settings.user_form.dni')}</p>
          <Form.Item name="group">
            <Input
              disabled={onlyRead}
              name="dni"
              style={{ border: '1px solid #121212' }}
              placeholder="xxxxxxxx-x"
              value={InfoUser ? InfoUser.dni : ''}
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
            />
          </Form.Item>
          <button
            disabled={false}
            className="btn-handle-password"
            onClick={() => showModal()}>
            <b>{t('settings.user_form.change_password')}</b>
          </button>
        </div>
      </div>

      <h1 className="projects-title div-projects">
        {t('settings.user_form.projects')}
      </h1>
      <CardsGeneral {...props} />

      <ModalPassword
        Visible={Visible}
        setVisible={setVisible}
        InfoUserProp={InfoUser}
        setInfoUser={setInfoUser}
      />
    </Fragment>
  );
}

SettinsUser.propTypes = {
  InfoUser: PropTypes.object.isRequired,
  setInfoUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(SettinsUser);
