import React from 'react';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { base } from '../../../../services/base';
/** import common functions from utils */
import { openNotification } from '../../../../utils';
import { withTranslation } from 'react-i18next';
import './index.css';
import './cardProfile.scss';

import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { getPermissionsByCommand } from '../../../../utils/partialPermissionUtils';
import EditableAvatar from '../../../Avatar/EditableAvatar';

const CardStyle = styled.div`
  .ant-card-body {
    padding: 0px !important;
    zoom: 1;
  }
`;

// Permissions
const userConfigPermissions = useConfigurationPermissions();

function CardProfile(props) {
  const { t } = props;
  const stateProject = useSelector((state) => state.projectState);
  const { InfoUser } = props;

  const history = useHistory();
  /** component button */
  const handleGoTo = (goTo) => {
    if (goTo.includes('/project')) {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
        return false;
      }
    }
    props.history.push(goTo);
  };

  const canDisplayProjectSettings =
    userConfigPermissions.project !== 'SA' &&
    getPermissionsByCommand('PROJECT_SETTINGS', 'GENERAL', 'SETTINGS');
  const canDisplayOrgSettings =
    userConfigPermissions.project !== 'SA' &&
    getPermissionsByCommand('ORGANIZATION_SETTINGS', 'GENERAL', 'SETTINGS');

  return (
    <CardStyle>
      <div style={{ marginTop: '140px', marginLeft: '20px' }}>
        <Card
          className="card-shadow"
          style={{
            width: 230,
            padding: '0px',
            height: '185px',
            borderBottom: '0px solid'
          }}>
          <div>
            <EditableAvatar
              user={InfoUser}
              size={100}
              className="FrmAvatar avatar-user-settings"
            />
          </div>
          <p className="text-center txt-profile-size-user">
            <b>{InfoUser ? InfoUser.name + ' ' + InfoUser.lastname : ''}</b>
          </p>
          <p className="text-center txt-profile-size-role">
            {InfoUser ? InfoUser.position : ''}
          </p>
        </Card>
        <Card
          className="card-shadow"
          size="default"
          style={{
            width: 230,
            borderTop: '1px solid #d1d1d1',
            padding: '0 0 16px 0'
          }}>
          <div
            className={
              'txt-profile-size ' +
              (history.location.pathname === '/settings/user' &&
                'bg-active-txt')
            }>
            <div onClick={() => handleGoTo('/settings/user')}>
              {t('settings.menu_profile')}
            </div>
          </div>

          {canDisplayProjectSettings ? (
            <div
              className={
                'txt-profile-size ' +
                (history.location.pathname === '/settings/project' &&
                  'bg-active-txt')
              }>
              <div onClick={() => handleGoTo('/settings/project')}>
                {t('settings.menu_project')}
              </div>
            </div>
          ) : null}

          {canDisplayOrgSettings ? (
            <div
              className={
                'txt-profile-size ' +
                (history.location.pathname === '/settings/company' &&
                  'bg-active-txt')
              }>
              <div onClick={() => handleGoTo('/settings/company')}>
                {t('settings.menu_organization')}
              </div>
            </div>
          ) : null}

          {userConfigPermissions.integrations === 'AC' ? (
            <div
              className={
                'txt-profile-size ' +
                (history.location.pathname === '/settings/plugins' &&
                  'bg-active-txt')
              }>
              <div onClick={() => handleGoTo('/settings/plugins')}>
                {t('settings.plugins_menu.title')}
              </div>
            </div>
          ) : null}
        </Card>
      </div>
      <div className="setting-project-version">
        {base.version ? `Outbuild v ${base.version}` : ''}
      </div>
    </CardStyle>
  );
}
CardProfile.propTypes = {
  InfoUser: PropTypes.object.isRequired,
  setInfoUser: PropTypes.func.isRequired,
  stateUpload: PropTypes.shape({
    loading: PropTypes.bool,
    imageUrl: PropTypes.string
  }).isRequired,
  setStateUpload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withTranslation()(CardProfile);
