import React, { useMemo } from 'react';
import { Card } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { base } from '../../../../services/base';
/** import common functions from utils */
import { openNotification } from '../../../../utils';
import { withTranslation } from 'react-i18next';
import './index.css';
import './cardProfile.scss';

import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { getPermissionsByCommand } from '../../../../utils/partialPermissionUtils';
import EditableAvatar from '../../../Avatar/EditableAvatar';

const CardStyle = styled.div`
  .ant-card-body {
    padding: 0px !important;
    zoom: 1;
  }
`;

function CardProfile(props) {
  const userConfigPermissions = useConfigurationPermissions();

  const { t } = props;
  const stateProject = useSelector((state) => state.projectState);
  const { InfoUser } = props;

  const canDisplayProjectSettings = useMemo(
    () =>
      userConfigPermissions?.project !== 'SA' &&
      getPermissionsByCommand('PROJECT_SETTINGS', 'GENERAL', 'SETTINGS'),
    [userConfigPermissions?.project]
  );

  const canDisplayOrgSettings = useMemo(
    () =>
      userConfigPermissions?.project !== 'SA' &&
      getPermissionsByCommand('ORGANIZATION_SETTINGS', 'GENERAL', 'SETTINGS'),
    [userConfigPermissions?.project]
  );
  const handleGoTo = (goTo) => {
    if (goTo.includes('/project')) {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
        return false;
      }
    }
    props.history.push(goTo);
  };

  const menuItems = [
    {
      path: '/settings/user',
      translationKey: 'settings.menu_profile',
      visible: true
    },
    {
      path: '/settings/project',
      translationKey: 'settings.menu_project',
      visible: canDisplayProjectSettings
    },
    {
      path: '/settings/company',
      translationKey: 'settings.menu_organization',
      visible: canDisplayOrgSettings
    },
    {
      path: '/settings/plugins',
      translationKey: 'settings.plugins_menu.title',
      visible: userConfigPermissions?.integrations === 'AC'
    }
  ];
  const location = useLocation();

  const isItemActive = (itemPath, index) => {
    const isPathMatch = location.pathname === itemPath;

    if (isPathMatch) {
      return true;
    }
    const isFirstItem = index === 0;
    const isAnyPathMatch = menuItems.some(
      (item) => location.pathname === item.path
    );
    if (isFirstItem && !isAnyPathMatch) {
      return true;
    }

    return false;
  };
  return (
    <CardStyle>
      <div style={{ marginTop: '140px', marginLeft: '20px' }}>
        <Card
          className="card-shadow"
          style={{
            width: 230,
            padding: '0px',
            height: '185px',
            borderBottom: '0px solid'
          }}>
          <div>
            <EditableAvatar
              user={InfoUser}
              size={100}
              className="FrmAvatar avatar-user-settings"
            />
          </div>
          <p className="text-center txt-profile-size-user">
            <b>{InfoUser ? InfoUser.name + ' ' + InfoUser.lastname : ''}</b>
          </p>
          <p className="text-center txt-profile-size-role">
            {InfoUser ? InfoUser.position : ''}
          </p>
        </Card>
        <Card
          className="card-shadow"
          size="default"
          style={{
            width: 230,
            borderTop: '1px solid #d1d1d1',
            padding: '0 0 16px 0'
          }}>
          {menuItems.map(
            (item, index) =>
              item.visible && (
                <div
                  key={item.path}
                  className={`txt-profile-size ${
                    isItemActive(item.path, index) ? 'bg-active-txt' : ''
                  }`}>
                  <div onClick={() => handleGoTo(item.path)}>
                    {t(item.translationKey)}
                  </div>
                </div>
              )
          )}
        </Card>
      </div>
      <div className="setting-project-version">
        {base.version ? `Outbuild v ${base.version}` : ''}
      </div>
    </CardStyle>
  );
}
CardProfile.propTypes = {
  InfoUser: PropTypes.object.isRequired,
  setInfoUser: PropTypes.func.isRequired,
  stateUpload: PropTypes.shape({
    loading: PropTypes.bool,
    imageUrl: PropTypes.string
  }).isRequired,
  setStateUpload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withTranslation()(CardProfile);
